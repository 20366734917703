<template>
  <el-dialog
    title="导入成功"
    :visible.sync="show"
    class="choose-selectedPlan-importSuccess"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :beforeClose="beforeClose"
    append-to-body
    width="600px"
  >
    <el-main>
      <div class="main flex">
        <i class="el-icon-check"></i>
        <div class="text1">导入完成</div>
        <div class="text2">
          导入数据共{{progress.total}}条，成功{{progress.ok}}条，
          <span class="fail">失败{{progress.bad}}条</span>
          <span v-if="progress.bad > 0">
            （
            <span class="detail" @click="seeDetail">点击查看详情</span>
            ）
          </span>
          用时{{progress.past_seconds}}秒
        </div>
        <el-button @click="toggle(false)">关闭</el-button>
      </div>
    </el-main>
  </el-dialog>
</template>

<script>
export default {
  // 导入成功
  name: 'importSuccess',
  props: {
    title: {
      tyle: String,
    },
    progress: Object,
  },
  components: {},
  data() {
    return {
      show: true,
      sum: 1000,
      success: 998,
      fail: 2,
      repeat: 2,
      consumeTime: 24,
    };
  },
  methods: {
    seeDetail() {
      this.$store.commit('changeComponent', 'importFail');
      this.toggle(false);
    },
    beforeClose(done) {
      done();
      this.$store.commit('changeComponent', '');
      this.toggle(false);
    },
    toggle(show) {
      this.show = show;
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__title {
  font-weight: bold;
}
.el-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .main {
    flex-direction: column;
    .el-icon-check {
      font-size: 60px;
      background: #52c41a;
      border-radius: 50%;
      color: white;
      padding: 10px;
      font-weight: bold;
    }
    .text1 {
      color: $h2;
      font-size: 16px;
      font-weight: 900;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .text2 {
      margin-bottom: 50px;
    }
    .fail {
      color: #ec808d;
    }
    .detail {
      color: #0079fe;
      text-decoration: underline;
      cursor: pointer;

      &:active {
        opacity: 0.7;
      }
    }
  }
}
::v-deep .el-dialog__title {
  font-weight: bold;
}
</style>

<template>
  <div class="left-nav">
    <div
      class="item"
    >{{$store.getters.active_top_nav && $store.getters.active_top_nav.meta && $store.getters.active_top_nav.meta.title}}</div>
    <div
      class="item"
      :class=" $store.getters.active_left_nav.id === item.id? 'active ':''"
      v-for="(item) in $store.getters.left_nav"
      :key="item.id"
      @click="change_left_nav(item)"
    >{{item.meta.title}}</div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {};
  },
  methods: {
    change_left_nav(data) {
      if (data.id === this.$store.getters.active_left_nav.id) {
        return;
      }
      if (this.$route.path !== data.path) {
        this.$router.push(data.path);
      }
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.left-nav {
  display: flex;
  flex-direction: column;
  min-width: 220px;
  padding-right: 10px;
  .item {
    border-bottom: 1px solid $bgc;
    padding: 20px 0 20px 60px;
    color: rgb(75, 85, 99);
    font-size: 15px;
  }
  .item:nth-of-type(1) {
    background-color: rgb(243, 244, 246);
    font-weight: bold;
  }
  .item:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  .item:nth-of-type(1):hover {
    opacity: 1;
  }
  .active {
    color: #0079fe;
    font-weight: bold;
  }
}
</style>

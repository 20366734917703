<template>
  <el-dialog
    append-to-body
    title="修改密码"
    ref="dialog"
    width="500px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :visible.sync="show"
    :beforeClose="beforeClose"
  >
    <el-form ref="form" :model="form.data" label-width="100px" :rules="form.rules" v-loading="form.loading">
      <el-form-item label="旧密码" prop="old_password">
        <el-input v-model="form.data.old_password" placeholder="请输入旧密码" type='password' show-password size="small" clearable maxlength="30"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="password">
        <el-input v-model="form.data.password" placeholder="请输入新密码" size="small" type='password' show-password clearable maxlength="30"></el-input>
      </el-form-item>
      <el-form-item label="重复新密码" prop="repeat_password">
        <el-input v-model="form.data.repeat_password" placeholder="请输入再输一遍新密码" type='password' show-password size="small" clearable maxlength="30"></el-input>
      </el-form-item>

      <div class="submit">
        <el-button type="primary" size="small" @click="submit" :loading="form.loading">确定</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import Login from '@/api/login';
export default {
  name: 'changePassword',
  props: {
    title: String,
    type: String,
    OrgTree: Array,
  },
  components: {},
  data() {
    let valid = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.data.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      show: false,
      form: {
        data: {
          old_password: '',
          password: '',
          repeat_password: '',
        },
        rules: {
          old_password: { required: true, message: '请输入旧密码', trigger: 'change' },
          password: { required: true, message: '请输入新密码', trigger: 'change' },
          repeat_password: { required: true, validator: valid, message: '请重复刚刚输入的密码', trigger: 'change' },
        },
        loading: false,
      },
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.loading = true;
          Login.changePassword(this.form.data)
            .then((res) => {
              console.log(res);
              this.$message.success('修改密码成功');
              return this.$emit('quit');
            })
            .finally(() => {
              this.form.loading = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    beforeClose(done) {
      done();
      this.$emit('changeShow', false);
    },
    toggle(show) {
      this.show = show;
      if (!show) {
        this.$emit('changeShow', false);
      }
    },
  },
  mounted() {},
  computed: {},
};
</script>

<style scoped lang="scss">
.submit {
  display: flex;
  justify-content: flex-end;
}
.el-cascader {
  width: 100%;
}
.el-select {
  width: 100%;
}
.info {
  padding-left: 108px;
  color: #aaa;
  padding-top: -10;
}
</style>

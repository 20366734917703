<template>
  <div class="header">
    <changePassword @changeShow="changeShow" v-if="show" ref="changePassword" @quit="quit"></changePassword>
    <div class="logo">
      <img src="@/assets/logo.png" alt="logo" />
      <div class="title">江西省人民陪审员选任系统</div>
    </div>
    <div class="header-nav">
      <div
        class="item"
        :class="item.id === $store.getters.active_top_nav.id ?'active ':''"
        v-for="(item) in $store.getters.top_nav"
        :key="item.id"
        @click="change_top_nav(item)"
      >{{item.meta.title}}</div>
    </div>
    <div class="el-userinfo">
      <img src="@/assets/layout/headPortrait.svg" alt />
      <div class="text">欢迎您！</div>
      <div>（{{$store.getters.user && $store.getters.user.username }}）</div>
      <el-button type="text" @click="changePassword">修改密码</el-button>
      <el-button type="text" @click="quit">退出</el-button>
    </div>
  </div>
</template>

<script>
import changePassword from './changePassword.vue';
import { initState } from '@/store';
export default {
  name: '',
  props: {},
  components: { changePassword },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    // 退出
    quit() {
      this.$myremove();
      this.$store.commit('init', initState);
      this.$router.push('/login');
    },
    changeShow(show) {
      this.show = show;
    },
    changePassword() {
      this.changeShow(true);
      this.$nextTick(() => {
        this.$refs.changePassword.toggle(true);
      });
    },
    // 顶部导航跳转
    change_top_nav(data) {
      console.log(data);
      if (data.id === this.$store.getters.active_top_nav.id) {
        return;
      }
      this.$store.commit('setLeftNav', data.id);
      if (this.$route.path !== data.path) {
        this.$router.push(data.path);
      }
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: repeating-linear-gradient(90deg, #495bff, #0acefe);
  color: white;
  box-sizing: border-box;
  max-height: 70px;
  .logo {
    display: flex;
    align-items: center;
    img {
      display: inline-block;
      width: 40px;
      height: 40px;
    }
    .title {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .header-nav {
    display: flex;
    .item {
      font-size: 20px;
      padding: 0 15px;
      font-weight: bold;
      min-width: 60px;
      height: 70px;
      line-height: 70px;
      box-sizing: border-box;
    }
    .item:hover {
      color: #ddd;
      cursor: pointer;
    }
    .item:active {
      color: #fff;
    }
    .active {
      border-bottom: 3px solid white;
    }
    .active:hover {
      color: #fff;
    }
  }
  .el-userinfo {
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    .text {
      padding-left: 10px;
      box-sizing: border-box;
    }
    .el-button {
      color: white;
      // font-size: 16px;
    }
  }
}
</style>

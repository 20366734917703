<template>
  <el-dialog
    title="导入常住居民表格"
    :visible.sync="show"
    class="choose-selectedPlan-import"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :beforeClose="beforeClose"
    append-to-body
    width="600px"
  >
    <el-form label-width="150px" ref="form" v-loading="loading" :element-loading-text="loadingTitle">
      <el-form-item label="选任单位：">
        <el-select v-model="xrdw" placeholder="请选择选任单位" disabled>
          <el-option label="区域一" value="shanghai"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="年度">
        <el-date-picker v-model="data.jhksnd" type="year" placeholder="请选择选任开始年度" disabled></el-date-picker>
      </el-form-item>
      <el-form-item label="模板下载：">
        <a href="/template.xlsx" target="_blank" download="人民陪审员报名人员模板.xlsx">人民陪审员报名人员模板.xlsx</a>
      </el-form-item>
      <el-form-item label="上传附件：">
        <div class="attach" v-loading="file_loading" element-loading-text="上传文件中">
          <div
            class="upload_file"
            @click="StartSelectFile"
            @drop="drop"
            @dragenter="dragenter"
            @dragleave="dragleave"
            @dragover="dragover"
            v-show="data.attach.length === 0"
          >
            <i class="el-icon-upload"></i>
            <div class="text">
              将要导入的常住居民表格拖拽到此处，或
              <span class="blue">点击上传</span>
            </div>
          </div>
          <div class="item" v-for="item in data.attach" :key="item.id">
            <div class="name">{{item.name}}</div>
            <i class="el-icon-circle-close" @click="removeAttach(item)"></i>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="show = false" :loading="loading">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </div>
    <input type="file" ref="file" class="inputfile" v-show="selectFile" @change="change" />
  </el-dialog>
</template>

<script>
import SelectPlan from '@/api/choose/selectPlan';
import UsualLive from '@/api/choose/usualLive';
import Public from '@/api/public';
export default {
  name: 'import',
  props: {
    title: {
      tyle: String,
    },
    progress: Object,
  },
  components: {},
  data() {
    return {
      data: {
        xrdw: '',
        xrdw_a: '',
        jhksnd: '',
        attach: [],
      },
      show: true,
      selectFile: false,
      loading: false,
      loadingTitle: '',
      xrdw: '',
      file_loading: false,
    };
  },
  methods: {
    dragenter(e) {
      e.preventDefault();
    },
    dragleave(e) {
      e.preventDefault();
    },
    //拖来拖去 , 一定要注意dragover事件一定要清除默认事件
    //不然会无法触发后面的drop事件
    dragover(e) {
      e.preventDefault();
    },
    //拖拽获取文件事件
    drop(e) {
      let a = { target: { files: [e.dataTransfer.files[0]] } };
      this.change(a);
      e.preventDefault();
    },
    beforeClose(done) {
      done();
      this.$store.commit('changeComponent', '');
      this.toggle(false);
    },
    submit() {
      if (this.data.attach.length === 0) {
        return this.$alert('请选择要上传的excel表格文件');
      }
      this.loading = true;
      this.loadingTitle = '正在准备上传';
      UsualLive.import(this.data.attach[0]).then(() => {
        this.$emit('getProgress', true);
      });
    },
    toggle(show, data) {
      this.show = show;
      if (show) {
        console.log(data);
        let { xrdw, jhksnd, attach, xrdw_a } = data;

        this.data = {
          xrdw,
          xrdw_a,
          jhksnd,
          attach,
        };
        this.data.attach = [];
      } else {
        this.show = show;
      }
    },
    removeAttach(data) {
      this.$refs.file.value = '';
      this.data.attach.some((item, index) => {
        if (item.id === data.id) {
          this.data.attach.splice(index, 1);
          return true;
        }
      });
    },
    change(e) {
      let file = e.target.files[0];
      console.log(file);
      let { name, size } = file;
      if (!/xlsx$/.test(name) && !/xls$/.test(name)) {
        this.$message.error('请选择Excel表格文件');
        this.removeAttach();
        return;
      }
      if (size / 1024 / 1024 > 100) {
        this.$message.error('您选择的Excel超过了100M');
        this.removeAttach();
        return;
      }
      this.file_loading = true;
      let formdata = new FormData();
      formdata.append('file', file);
      SelectPlan.loadExcel(formdata)
        .then((res) => {
          console.log(res);
          let { data_key } = res;
          this.data.attach.push({ name: file.name, data_key });
        })
        .finally(() => {
          this.file_loading = false;
        });
    },
    StartSelectFile() {
      this.selectFile = true;
      this.$nextTick(() => {
        this.$refs.file.click();
      });
    },
    setProgress() {
      this.loading = true;
      this.loadingTitle = '正在获取是否有正在上传的进度';
      Public.components({ ImportProgress: 'czjm' })
        .then((res) => {
          if (!res.ImportProgress) {
            return;
          } else {
            if (res.ImportProgress.finish) {
              return;
            } else {
              this.$emit('setProgress', res.ImportProgress);
              this.toggle(false);
              this.$store.commit('changeComponent', 'importProgress');
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {},
  created() {
    this.xrdw = this.$store.getters.account.org.name;
    this.data.jhksnd = this.$store.getters.year;
    return this.setProgress();
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.el-dialog {
  .el-form {
    .el-date-editor {
      width: 100%;
    }
    .attach {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        i:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }
    .el-select {
      width: 100%;
    }
    .upload_file {
      background-color: #fff;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      box-sizing: border-box;
      width: 360px;
      height: 180px;
      text-align: center;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      .el-icon-upload {
        font-size: 67px;
        color: #c0c4cc;
        margin: 40px 0 16px;
        line-height: 50px;
      }
      .text {
        .blue {
          color: #409eff;
        }
      }
    }
  }
  .inputfile {
    opacity: 0;
    z-index: -1;
    position: absolute;
  }
}
::v-deep .el-dialog__title {
  font-weight: bold;
}
</style>

<template>
  <section
    class="layout"
    v-loading.fullscreen="loading.loading"
    :element-loading-text="loading.title"
    :element-loading-background="loading.color"
  >
    <myheader></myheader>
    <div class="main">
      <left></left>
      <div class="container">
        <div class="title">
          <div class="text">{{active_left_nav && active_left_nav.meta && active_left_nav.meta.title}}</div>
        </div>
        <div class="layout-router-view">
          <transition name="el-fade-in-linear">
            <router-view />
          </transition>
        </div>
      </div>
    </div>
    <component
      :is="componentName"
      ref="component"
      :background="background"
      :progress="progress"
      @changeBackground="changeBackground"
      @getProgress="getProgress"
      @setProgress="setProgress"
      @clear="clear"
    ></component>
    <div class="background" v-show="background && progress && !progress.finish ">
      <div class="title">数据导入中</div>
      <div class="text">导入数据共{{progress.total}}条，已处理{{progress.handle}}条，失败{{progress.bad}}条;</div>
      <div class="text">正在导入，请稍后...</div>
      <el-button type="text" @click="changeBackground(false,'importProgress')">恢复窗口</el-button>
    </div>
    <div class="background_Success" v-show="background && progress.finish">
      <div class="title">数据导入完成</div>
      <div class="text">导入数据共{{progress.total}}条，已处理{{progress.handle}}条，失败{{progress.bad}}条;</div>
      <div class="text">用时{{progress.past_seconds}}秒</div>
      <el-button type="text" @click="changeBackground(false,'importSuccess')">恢复窗口</el-button>
    </div>
  </section>
</template>

<script>
import Public from '@/api/public';
import left from './left.vue';
import myheader from './header.vue';
import { mapState } from 'vuex';
let components = {};
let files = require.context('./importPeople', false, /.vue$/);
files.keys().forEach((item) => {
  let name = item.replace(/^.\//, '').replace(/.vue$/g, '');
  components[name] = files(item).default;
});
export default {
  name: '',
  props: {},
  components: { ...components, left, myheader },
  data() {
    return {
      secendRouter: '/choose',
      selectedName: '',
      OrgTree: [],
      progress: {}, //进度条
      timeout: '', //进度定时器
      background: false,
    };
  },
  methods: {
    changeBackground(background, name) {
      this.background = background;
      if (!background) {
        this.$store.commit('changeComponent', name);
      }
    },
    clear() {
      clearInterval(this.timeout);
    },
    setProgress(progress) {
      this.progress = progress;
    },
    getProgress() {
      clearInterval(this.timeout);
      this.timeout = setInterval(() => {
        Public.components({ ImportProgress: 'czjm' }).then((res) => {
          if (!res.ImportProgress) {
            clearInterval(this.timeout);
            return;
          } else {
            if (res.ImportProgress.finish) {
              this.$store.commit('changeComponent', 'importSuccess');
              clearInterval(this.timeout);
            }
            this.progress = res.ImportProgress;
            if (!this.background) {
              this.$store.commit('changeComponent', 'importProgress');
            } else {
              this.$store.commit('changeComponent', '');
            }
          }
        });
      }, 2000);
    },
  },
  mounted() {},
  created() {},
  computed: {
    ...mapState(['left_nav', 'top_nav', 'active_top_nav', 'active_left_nav', 'loading','componentName']),
  },
  watch: {
    '$route.path': {
      immediate: true,
      async handler(newRouter) {
        if (newRouter !== '/' && newRouter !== '/login') {
          let path = this.$route.fullPath;
          if (this.top_nav.length > 0) {
            this.top_nav.forEach((item) => {
              let reg = new RegExp('^' + item.path);
              if (reg.test(path)) {
                this.$store.commit('set_active_top_nav', item);
                return true;
              }
            });
          }
          if (this.left_nav.length > 0) {
            this.left_nav.some((item) => {
              if (item.path === path) {
                this.$store.commit('set_active_left_nav', item);
                return true;
              }
            });
          }
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.layout {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .main {
    flex: 1;
    display: flex;
    flex-direction: row;
    .container {
      width: 100%;
      flex: 1;
      background-color: $bgc;
      display: flex;
      flex-direction: column;
      height: 100%;
      .title {
        display: flex;
        align-items: center;
        background-color: #fff;
        height: 43px;
        padding-left: 50px;
        color: $h4;
        .text {
          border-left: 3px solid #0079fe;
          padding-left: 5px;
          font-weight: 700;
          font-size: 18px;
        }
      }
      .layout-router-view {
        flex: 1;
        padding: 15px 30px;
        box-sizing: border-box;
        overflow: initial;
      }
    }
  }
}
.background {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  width: 355px;
  right: 40px;
  bottom: 30px;
  font-family: 'Arial Normal', 'Arial', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  letter-spacing: normal;
  color: #333333;
  box-sizing: border-box;
  border: 1px solid rgba(233, 233, 233, 1);
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  .title {
    text-align: left;
    font-weight: bold;
    font-size: 15px;
  }
  .text {
    text-align: left;
    padding-top: 10px;
  }
  .el-button {
    text-align: right;
    padding-top: 0;
  }
}
.background_Success {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  width: 355px;
  right: 40px;
  bottom: 30px;
  font-family: 'Arial Normal', 'Arial', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  letter-spacing: normal;
  color: #333333;
  box-sizing: border-box;
  border: 1px solid rgba(233, 233, 233, 1);
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  .title {
    text-align: left;
    font-weight: bold;
    font-size: 15px;
  }
  .text {
    text-align: left;
    padding-top: 10px;
  }
  .el-button {
    text-align: right;
    padding-top: 0;
  }
}
</style>
